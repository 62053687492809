import store from '@/Core/Store'

const routes = [
    {
        path: "/logout",
        component: () => {
            store.dispatch("auth/logout");
        }
    }
];

export default routes;
