const translations = {
    tr: "Türkçe",
    en: "English",

    // A
    all : 'Tümü',
    account: 'Hesap',

    // B
    branch: 'Şube',

    // C
    clear: 'Temizle',
    current_password: "Mevcut Şifre",
    check_internet: "İnternete bağlı değilsiniz. Bağlantınızı kontrol edin.",
    connect_internet: "İnternete bağlanın",

    // D
    detailed_search: 'Detaylı Arama',
    download:'İndir',
    delete:'Sil',
    delete_sure:'Silmek istediğinizden emin misiniz?',

    // E
    explanation:'Açıklama',
    edit:'Düzenle',
    email:'Email',
    error: 'Hata',

    // F
    forgot_password:'Şiremi Unuttum',

    // H
    help:'Yardım',

    // I
    id:'ID',

    // L
    logout: 'Oturumu kapat',
    login:'Giriş Yap',

    // N
    new : 'Yeni',
    new_password: "Yeni Şifre",
    new_password_repeat: "Yeni Şifre Tekrar",
    new_add: 'Yeni Ekle',
    name:'Ad',
    no:'Hayır',
    no_result:'Kayıt bulunamadı',

    // O
    other_roles:'Diğer Roller',

    // P
    password:'Şifre',
    phone:'Telefon',
    permission_information: "İzin Bilgileri",
    password_contains_eight_characters_message: 'Şifreniz en az 8 karakterden oluşmalıdır.',
    password_contains_uppercase_message:'Şifrenizde 1 adet büyük harf içermelidir.',
    password_contains_lowercase_message:'Şifrenizde 1 adet küçük harf içermelidir.',
    password_contains_special_character_message:'Şifrenizde 1 adet özel karakter(!@#$%&*...) içermelidir.',
    password_equals_message:'Girmiş olduğunuz şifreler eşit olmalıdır.',
    password_contains_number_message:'Şifrenizde 1 adet sayısal karakter içermelidir.',

    // R
    roles:'Roller',
    role:'Rol',
    role_permissions: "Rol Yetkileri",
    remember_me:'Beni hatırla',
    return_login:'Girişe dön',

    // S
    something_went_wrong: "Bir Hata Oluştu. Lütfen daha sonra tekrar deneyiniz.",
    save:'Kaydet',
    show:'Görüntüle',
    search:'Ara',
    send:'Gönder',
    settings:'Ayarlar',
    security:'Güvenlik',
    surname:'Soyad',

    // T
    twofactor:'İki adımlı doğrulama',
    twofactor_authentication:'İki adımlı doğrulama (2FA)',
    twofactor_authentication_text:'Bu özellik, oturum açma sırasında ek bir kod gerektirerek hesabınızı yetkisiz erişime karşı korumaya yardımcı olmak için İki Faktörlü Kimlik Doğrulamayı (2FA) etkinleştirmenize olanak tanır.',

    // U
    update:'Güncelle',

    // Y
    yes:'Evet',
    you_have_tried_too_many: "Çok fazla deneme yaptınız.",
    you_can_try_again_after_1_minute: "1 dakika sonra tekrar deneyebilirsiniz.",
    you_must_fill_in_the_fields_indicated_in_red: "You are required to fill in the fields indicated in red",
    your_selected_permission: "Seçtiniz Yetkiler",

};

export default translations
